import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Wide from "../components-styled/Wide"
import PictureSet from "../components/PictureSet"
import imgSet13 from "../imgsets/imgset13"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Wide>
      <PictureSet imgSet={imgSet13} />
    </Wide>
    <h1>Ressource nicht gefunden</h1>
    <p>
      Die Ressource, die du gesucht hast, existiert leider nicht. Bitte beginne
      am <Link to="/">Start</Link> und prüfe, ob du findest, wonach du gesucht
      hast. Danke.
    </p>
  </Layout>
)

export default NotFoundPage
